:root {  
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em;  /* must NOT be a percentage value */   
    
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    
     /*General*/
    --container-max-width: 1400px;
    --fixed-header-height: 80px;
   
    /* Slick */
    --slick-slider-height: 60vh;
    
    /* change settings for headings */
    --text-color: #777 /*#eee*/;
    --body-bg: #eeecec /*#1F1F1F*/;
    --main-color: #1F1F1F;
    --accent-color: /*#ad3131 #f38629 */ #3e5578;
    --effect-color: #777;
    --header-text-color: #fff;
    --header-bg-color: var(--main-color);
    --footer-bg-color: var(--main-color);
    --footer-text-color: #ccc;
    --menu-bg-color: inherit;
    --menu-bg-hover: var(--accent-color);
    --menu-li-a-padding: 10px 15px;
    --link-color: inherit;
    --link-hover-color: inherit;
    --map-marker-color: 000;

    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 15px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    /* Fonts */
    --base-font-family: 'Raleway', 'sans-serif';
    --alternative-font-family: Gruppo, Helvetica, Arial, sans-serif;  
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
@import "/fonts/Gruppo/all.css"; /* 400 */
@import "/fonts/Raleway/all.css"; /* 300, 400, 700 */
@import "/cssmodules/animate.css";

/*--------------------------------------------
General
---------------------------------------------*/

.homepage {
    color: #fff;
}

img {
    max-width: 100%;
    height: auto;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height:100%;
}

body {
    background-image: url('/theme/intendit/graphics/mbg2.png');
    background-repeat: no-repeat;
    background-position: top right;
    font-family: var(--base-font-family);
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    background-color: var(--body-bg);
    letter-spacing: 1px;
}
/*
body.homepage  {
    background-image:none;
    background-color: #E4E2E2;
}*/

h1, h2, h3, h4 {
    font-family: var(--alternative-font-family);
}

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

h1.twelve {
    max-width: 100%;
    margin-left: .8rem;
}

/*
.container h1 {
    margin: 2rem 0.8rem;
}*/

h2 {
    font-size: calc(1.1 * var(--scale-factor)rem);
}

h3 {
    font-size: 1.5rem;
}

h4 {
    font-size: 1.2rem;
}

a {
    color: var(--link-color);
    transition: 0.5s ease;
}

a:hover {
    color: var(--link-hover-color);
}

.markerLabels {
    overflow: visible !important;
}

.fa-map-marker {
    background: url(/extensions/local/intendit/intenditExt/web/black/map-marker.svg);
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*--------------------------------------------------------
Header
---------------------------------------------------------*/
header {
    
}

header .container {
    padding :0 0.5em;
}

header a {
    text-decoration: none;
}




header {
    position: relative;
    z-index: 1;

    background: rgba(255,255,255,.6);
}


.logo {
    padding: 1rem;
}

.logo a {
    font-size: 0;
}

.menu li {
    margin:0;
}

.menu li a {
    font-family: var(--alternative-font-family);
    padding: var(--menu-li-a-padding);
    color: var(--text-color); 
    transition: 0.5s ease-in;
    text-transform: uppercase;
    font-size: 1.2rem;
}

.menu li a:hover, .menu .active a {
    color: #000; 
}

/**** Dropdown ****/

.menu .dropdown ul {
    z-index: 100;
    border-bottom: none;
}

.menu li:hover ul {
    margin-left: 0;
}

.menu .sub-menu-items li a { 
    margin: 0; 
    background: #f5f5f5;
    color: #222;
    font-size: 1.1rem;
    min-width: 300px;
    padding: .7rem;
}

.menu.menu .sub-menu-items li a:hover { 
    background: var(--accent-color); 
    color: #fff;
}


/**** Leftmenu ****/

.submenu ul {
    margin-top: 2.8rem;
    padding: 0;
}

.submenu ul li {
    list-style-type: none;
    padding: var(--padding);
    border-bottom: 1px solid #eee;
    background: #fff;
} 

.submenu ul li a  {
    text-decoration: none;
    color: var(--effect-color);
}

.submenu li a:hover, .submenu .active a {
    color: var(--accent-color);
}

/*--------------------------------------------------------
Slider startsida
---------------------------------------------------------*/
.topslider .inner {
    border: 20px solid rgba(255,255,255,.9);
}

.slick-slider {
    margin-bottom: 0;
}

.block-1 {

}

.topslider .slick-slide {  
    height: var(--slick-slider-height);
    margin-top: 3vh;
}

.topslider .slick-slide {
    background-size: cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
} 

.topslider h2 {
    font-size: 3rem;
}

.topslider-logo {
    margin: 0 auto;
    text-align: center;
}

.slick-slide img {
    display: inline !important;
}

.topslider .slick-title {
    text-align: center;
    color: white;
    animation: slick-title-anim .5s;
    
    display: none;
}

.topslider .slick > .align.center:first-child{
    min-height: 50vh;
}

.topslider .slick > .align.center:not(:first-child) {
    display: none;
}

@keyframes slick-title-anim {
    0% { 
        opacity:0; 
        transform: scaleY(.5);
    }
    
    70% {
        transform: scaleY(1.1);
    }
    
    100% { 
        opacity:1;
        transform: scaleY(1);
    }
}


.slick-dots {
    bottom: -70px;
}

.slick-dots li button:before {
    font-size:0rem;
    opacity:1.0;
    color: var(--accent-color);
    width:40px;
    height:8px;
}

.slick-dots li.slick-active button:before {
    background:var(--accent-color);
    display:block;
}

.slick-dots li {
    background: #ccc;
    border-radius:0%;
    margin:0 2px;
    width:40px;
    height:8px;
    z-index: 99999;
}


/** arrows **/
.topslider .slick-prev, .topslider .slick-next {
    width:auto;
    height:auto;
}

.topslider .slick-next {
    right:50px;
    z-index:100;
}

.topslider .slick-prev {
    left:50px;
    z-index:100;
}

.topslider .slick-next, .topslider .slick-prev {
    opacity: 1;
}

.topslider .slick-next:hover, .topslider .slick-prev:hover {
    border:none;
}

.topslider .slick-next:before, .topslider .slick-prev:before {
    transition: 0.8s ease;
    background:rgba(255,255,255,0.7);
    padding: 1.5em;
    font-family: Roboto;
    line-height: normal;
    color: #222;
}

.topslider .slick-next:hover:before, .topslider .slick-prev:hover:before {
    background: white;
    color: black;
}

.topslider .slick-next:before {
    content:"»";
}

.topslider .slick-prev:before {
    content:"«";
}

/* Content-startpage | Blocks
---------------------------------------------------------*/

.inner-content {
    color: #222;
    text-align: center;
}


.block-2  {
    margin: 0 auto;
    text-align: center;
   /* background: #E4E2E2;*/
}


.car-block {
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fcfcfc+0,eeeeee+100 */
background: #fcfcfc; /* Old browsers */
background: -moz-linear-gradient(45deg,  #fcfcfc 0%, #eeeeee 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(45deg,  #fcfcfc 0%,#eeeeee 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(45deg,  #fcfcfc 0%,#eeeeee 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfcfc', endColorstr='#eeeeee',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
color: #222;
}

.boxes .boxwrapper {
    position: relative;
}

.boxes .box-teaser {
    z-index: 101;
    opacity: 0;
    text-align: center;
    transition: 0.5s ease;
    color: white;
    font-size: .9rem;
}

.boxes .boxwrapper:hover .box-teaser {
    opacity: 1;
}

.boxes .boxwrapper:after {
    content: '';
    background: rgba(0,0,0,0);
    transition: background .65s ease-in 0s;
    z-index: 2;
}

.boxes .boxwrapper:hover:after {
    background: rgba(0,0,0,0.7);
    transition: background .65s ease-out 0s;
}




.car-img .status {
    display: inline-block;
    background: rgba(0,0,0,.7);
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #ccc;
    padding: 0.7em;
    position: absolute;
    max-width: 50%;
    top: 10%;
    font-weight: 100;
    margin-top: 100px;
}

.car-block-heading h2 {
    text-align: center;
    font-size: 2.7rem;
    background: var(--accent-color);
    color: #fff;
    padding: 1rem;
}


.car-item span {
    font-weight: bold;
}

.contact-block {
    background: linear-gradient(0deg,rgba(0,0,0,.1),rgba(0,0,0,.8)),url('/theme/intendit/graphics/cbg.jpg') fixed center left;
}

.reco {
    background: #fff;
    padding: 3rem 0 6rem;
}

.bottom-block {
    background: linear-gradient(0deg,rgba(173,49,49,.8),rgba(173,49,49,.8)),url('/theme/intendit/graphics/bottom-block.jpg') fixed center left;
    min-height: 300px;
}

/* Content - all
---------------------------------------------------------*/

.teaser {
    /*animation: teaserfade 1.5s;*/
    font-size: 2rem;
    font-family: var(--alternative-font-family);
    line-height: 1;
    padding-bottom: 2rem;
}

@keyframes teaserfade {
    0% {
        opacity: 0;
        transform: scaleY(.6) translateX(-100px);
    }

    100% {
        opacity: 1;
        transform: scaleY(1) translateX(0px);
    }
}

/* Sidan Bilar */

.go-back {
    margin-top: 1rem;
}

.go-back a {
    text-decoration: none;
}

.go-back a:before {
    content: '<< ';
}

.car-item-outer {
    background: rgba(255,255,255,.7);
    margin-top: 50px;
}

.car-item-outer h1 {
    text-align: center;
}

.car-info {
    margin-top: 10px;
}

.slider-for img {

}

.slider-nav img {
    border-right: 5px solid transparent;
}

.bilar h3 {
    padding-bottom: 0;
}

.car {

}

.car span {
    font-weight: bold;
}

.car .car-img .status {
    top: -10%;
}

.sold-cars h2 {
    font-size: 2.4rem;
    text-align: center;
}

/* Title Sålda Bilar */

.h h2 {
    color: #fff;
    text-align: center;
}

.h {
  z-index: 1;
  position: relative;
    margin-top: 100px;
    width: 100%;
}

.h .skew:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: visible;
  width: 100%;
  height: 150px;
  background: var(--accent-color);
  z-index: -1;
  -webkit-transform: skewY(-2deg);
  -moz-transform: skewY(-2deg);
  -ms-transform: skewY(-2deg);
  -o-transform: skewY(-2deg);
  transform: skewY(-2deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: initial;
}

.h .skew .h-inner {
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}



.sold-cars {
    margin-top: 100px;
}



/*Instagram album*/
.album-bild {
    position:relative;
    margin: 5px;
}
.album-bild img {
    display:block;
 }
.info { 
    opacity:0;  
    position:absolute; 
    width:100%; 
    height:100%; 
    top:0; 
    left:0; 
    right:0; 
    bottom:0; 
    z-index:1; 
    padding: 10px; 
    font-size:0.9em; 
    line-height:1.4em; 
    text-align:right; 
    color:#777;  
    background: linear-gradient(to bottom, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0.9) 100%); 
    transition: .5s ease;
}
.info img { 
    display: inline-block;
    height:17px; 
    width:auto; 
    margin:0 0 0 5px; 
    vertical-align:top !important; 
    fill:#777 !important;
}
.album-bild:hover .info {
    opacity:1; 
}

/** Reco **/

.reco iframe {
    max-width: 100%;
}


/* Boxar / Zoomboxar
-------------------------------------------------*/

.boxes {

}

.boxes a {
    text-decoration: none;
    color: #fff;
}

.boxes a:hover {
    color: #ccc;
}

.boxbutton {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    margin-top: 2rem;
}

.boxbutton, button, input[type=reset], input[type=submit] {
    border: none;
    border-radius: 0;
    padding: 0.5rem;
    background: var(--accent-color);
}

.boxwrapper {
    height: 25vh;
    background-size: cover;
    background-position:center;
}


.zoombox .boxwrapper {
    background-size: 0% 0%;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.boxwrapper h2 {
    color: #fff;
    margin: 0;
    text-align:center;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.8);
    z-index: 3;
    font-weight: 400;
    margin-bottom: 1rem;
    font-size: 2.5rem;
}

.zoombox .boxwrapper[style]:before, .boxwrapper:after, .boxwrapper figure img, .boxwrapper figure figcaption {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.zoombox .boxwrapper[style]:before {
    content: '';
    background-image: inherit;
    background-size: cover;
    background-position: center center;
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper figure img {
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper:after {
    content: '';
    background: rgba(0,0,0,0);
    transition: background .65s ease-in 0s;
    z-index: 2;
}

.zoombox .boxwrapper:hover:after {
    background: rgba(0,0,0,0.3);
    transition: background .65s ease-out 0s;
}

.zoombox .boxwrapper[style]:hover:before, .boxwrapper:hover figure img {
    transform: scale(1.3);
    transition: transform .65s ease-out 0s;
}

.zoombox .box-teaser {
    z-index: 101;
    opacity: 0;
    text-align: center;
    transition: 0.5s ease;
    color: white;
}

.zoombox .boxwrapper:hover .box-teaser {
    opacity:1;
}


/* Contact
---------------------------------------------------------*/

.kontakt svg {
    margin-right: 0.5rem;
    fill: #777;
}


/* listing items
---------------------------------------------------------*/

.mfp-title, .mfp-counter {
    display: none;
}

.pagination ul {
    float: left;
    clear: both;
    display: block;
    margin: 8px 0;
    padding: 0;
    border: 1px solid #DDD;
}

.pagination ul li {
    float: left;
    list-style-type: none;
    border-right: 1px solid #DDD;
    padding: 4px 6px;
}

.pagination ul li:last-child {
    border-right: none;
}

.pagination ul li.active {
    font-weight: bold;
    background-color: var(--link-color);
}

.pagination ul li.active a {
    color:white;
}

.pagination ul li a {
    text-decoration: none;
}

/* Footer
---------------------------------------------------------*/


footer {
    background: var(--footer-bg-color);
    color: var(--footer-text-color);
    margin-top: 180px;
}

.footerlogo img {
    position: relative;
    max-width:140px;
    opacity: .6;
    /*margin-top: -100px;
    z-index: 99; */
}

.homepage footer {
    margin-top: 0;
}

footer a {
    text-decoration:none;
}

footer svg.fa-icon {
    fill: var(--footer-text-color);
    height: calc(1 * var(--scale-factor)rem);
    transition: ease-in 0.5s;
}

footer svg.fa-icon:hover {
    fill: var(--accent-color);
}

/*Google maps*/
.map-canvas {
    height: 30vh;
    margin-top: -45px;
}

.map-canvas img {
    max-width: none;
}
 
/*Intendit footer*/
.created-by {
    background: var(--accent-color);
    padding: 0.5em 0;
    text-align: right;
}

.created-by .container, .intenditfooter {
    padding: 0;
    margin: 0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
     color: color(var(--accent-color) lightness(70%));
}


/*------------------------------------------
Sticky footer
-------------------------------------------*/
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%; /* IE fix */
}

header, footer {
    flex: none;
}

footer .contactinfo p {
    margin:0;
}


/* general
---------------------------------------------------------*/

.boltform {
    margin: 0 auto;
    color: #fff;
    max-width: 80%
}

.boltform textarea {
    min-height:120px;
}

input[type=email], input[type=number], input[type=search], input[type=text], input[type=tel], input[type=url], input[type=password], textarea {
    width: 100%;
    color: #fff !important;
    padding: 1rem;
    background: rgba(0,0,0,.7);
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: none;
}


.contact-block .boltforms-row {
    float: left;
    width: 33.3%;
    padding-right: 10px;
}

.contact-block .boltforms-row:nth-of-type(4) {
    float: left;
    width: 100%;
}

.contact-block .boltforms-row:nth-of-type(5) {
    float: right;
    width: 15%; 
}

button[type=submit], input[type=submit] {
    margin:0;
    background: var(--accent-color);
    border: none;
    transition: ease-in 0.5s;
    width: 100%;
    border-radius: 0;
}

button[type=submit]:hover, input[type=submit]:hover {
    background: #fff;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.4);
}

::-webkit-input-placeholder {
    color: #ccc;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.4);
}

:-moz-placeholder {
    color: #ccc;
    opacity: 1;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.4);
}

::-moz-placeholder {
    color: #ccc;
    opacity: 1;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.4);
}

:-ms-input-placeholder {
    color: #ccc;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.4);
}

/** Sälja bil **/
.sellcar-form .boltforms-row {
    float: left;
    width: 100%;
}

.sellcar-form .boltforms-row:nth-of-type(1)  {
    float: left;
    width: 33%;
    padding-right: 10px;
}

.sellcar-form .boltforms-row:nth-of-type(2)  {
    float: left;
    width: 33%;
    padding-right: 10px;
}

.sellcar-form .boltforms-row:nth-of-type(3)  {
    float: left;
    width: 33%;
}

.sellcar-form .boltforms-row:nth-of-type(4)  {
    float: left;
    width: 33%;
    padding-right: 10px;
}
.sellcar-form .boltforms-row:nth-of-type(5)  {
    float: left;
    width: 33%;
    padding-right: 10px;
}
.sellcar-form .boltforms-row:nth-of-type(6)  {
    float: left;
    width: 33%;
}

.sellcar-form .boltforms-row:nth-of-type(7) {
    float: left;
    width: 50%;
    padding-right: 10px;
}

.sellcar-form .boltforms-row:nth-of-type(8) {
    float: left;
    width: 50%;
    padding-right: 10px;
}

.sellcar-form .boltforms-row:nth-of-type(9), .sellcar-form .boltforms-row:nth-of-type(10) {
    padding-right: 10px;
}

/* Köpa bil */
.buycar-form .boltforms-row {
    width: 100%;
}

.buycar-form .boltforms-row:nth-of-type(1)  {
    float: left;
    width: 50%;
    padding-right: 10px;
}

.buycar-form .boltforms-row:nth-of-type(2)  {
    float: left;
    width: 50%;
}

.buycar-form .boltforms-row:nth-of-type(3)  {
    float: left;
    width: 50%;
    padding-right: 10px;
}
    
.buycar-form .boltforms-row:nth-of-type(4)  {
    float: left;
    width: 50%;
}
    

.fa-map-marker{
    background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*Not found*/
.pagenotfound {min-height: 100vh; position:relative; text-align:center; font-size:1.1em; }
.pagenotfound .logo img {max-height: 70px; width:auto; max-width: 100%; display:block; }
.pagenotfound ul {list-style-type:none; padding: 0; margin: 0.5em auto; width:100%; max-width: 550px; }
.pagenotfound li a { border-bottom: 1px dashed #d0d0d0; display: inline-block;  padding: 10px 110px; }

/* Media Queries
---------------------------------------------------------*/

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
    
    .intenditfooter {
        text-align: center;
    }
    
    .boxwrapper {
        height: 45vh;
    }
}


@media (max-width: var(--breakpoint-menu)) {
   
    .menu .dropdown ul {
        border: none !important;
        background: var(--accent-color) !important;
        width: 100%;
        padding-left: 30px;
    }
    
    .menu .dropdown ul li a {
       font-size: .8rem;
    }
    
    .menu li, .menu li ul, .menu li:hover ul {
        margin: 0 !Important;
    }
    
    .menu.menu li {
        margin: 0;
    }

    .menu.menu li a {
        margin: 0;
        background: var(--accent-color);
        color: #fff;
    }
    
    .menu .active a {
        color: #fff;
        background: #333 !important;
    }
    
    #menu-icon:before, #menu-icon:after, #menu-icon span {
        border: 1px solid #000;
        text-align: center;
    }
    
    .car-block-heading h2 {
        font-size: 2rem;
    }
    
    .fb-block {
        padding-top: 0;
     }
    
    .contact-block .boltforms-row {
    width: 100%; 
    }
    
    .contact-block .boltforms-row:nth-of-type(5) {
        width: 100%;
    }
    
    .boxes .box-teaser {
        opacity: 1;
    }
    
    footer {
        text-align: center;
    }
    
    .boltform {
        max-width: 100%;
    }
    
    .sellcar-form .boltforms-row:nth-of-type(1), .sellcar-form .boltforms-row:nth-of-type(2),  .sellcar-form .boltforms-row:nth-of-type(4),
    .sellcar-form .boltforms-row:nth-of-type(5), .sellcar-form .boltforms-row:nth-of-type(7),
    .sellcar-form .boltforms-row:nth-of-type(8) {
        width: 100%;
    }
    
    .sellcar-form .boltforms-row:nth-of-type(3), .sellcar-form .boltforms-row:nth-of-type(6) {
        width: 100%;
        padding-right: .6rem;
    }
    
    .buycar-form .boltforms-row:nth-of-type(1), .buycar-form .boltforms-row:nth-of-type(2), .buycar-form .boltforms-row:nth-of-type(3), .buycar-form .boltforms-row:nth-of-type(4)   {
        width: 100%;
    }
    
    .buycar-form .boltforms-row:nth-of-type(1), .buycar-form .boltforms-row:nth-of-type(3) {
        padding-right: 0;
    } 
    
}

.fb-block {
    position: relative;
    padding-top: 70px;
}



/*Facebook album*/
.album-bild {
    position:relative;
}
.album-bild img {
    display:block;
}
.info { 
    opacity:0;  
    position:absolute; 
    width:100%; 
    height:100%; 
    top:0; 
    left:0; 
    right:0; 
    bottom:0; 
    z-index:1; 
    padding: 10px; 
    font-size:0.9em; 
    line-height:1.4em; 
    text-align:right; 
    color:#e4e4e4;  
    background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.9) 100%); 
}
.info img { 
    display: inline-block;
    height:17px; 
    width:auto; 
    margin:0 0 0 5px; 
    vertical-align:top !important; 
    fill:#e4e4e4;
}
.album-bild:hover .info {
    opacity:1; 
}
